import React, { useState, useContext, useEffect, useRef } from "react"
import { useHistory } from "react-router-dom"
import useMeasure from "react-use-measure"
import mergeRefs from "react-merge-refs"

import { useProject } from "../../library/api"
import ProjectBody from "./ProjectBody"
import ProjectSummary from "../../components/ProjectSummary"
import ProjectFooter from "./ProjectFooter"
import ScrollContext from "../../library/ScrollContext"
import useArrowKeys from "../../library/useArrowKeys"
import isTop from "../../library/isTop"
import ProjectHeader from "./ProjectHeader"

const projectLoadThreshold = 50 // px, from above

const SliderProject = ({
  isCurrent,
  project: { title, client, image, slug, description, tags, slider, srcset, videoUrls },
  onClickRight,
  onClickLeft,
  onClickFooterNext,
  footerNextText,
}) => {
  const isArchive = slider !== 1

  const scrollRef = useRef(null)
  const [measureRef, { y }] = useMeasure({ scroll: true })

  const scrollDown = () =>
    scrollRef.current.scrollIntoView({ behavior: "smooth" })

  const [isOpen, setOpen] = useState(false)
  const { scrollPos } = useContext(ScrollContext)
  const history = useHistory()

  useEffect(() => {
    // Open project (triggers load) when it's the current project and scrolled down
    isCurrent && scrollPos > projectLoadThreshold && setOpen(true)
  }, [scrollPos, isCurrent])

  useEffect(() => {
    // Scroll down when coming from a group member link
    if (
      isCurrent &&
      history.location.state &&
      history.location.state.fromGroup
    ) {
      setTimeout(scrollDown, 100)
    }
  }, [isCurrent, history.location.state])

  // If project is open due to scroll position, load the body.
  const { project: projectBody, isLoading } = useProject(isOpen && slug)

  useArrowKeys(
    isCurrent
      ? {
          left: !isArchive && (() => isTop() && onClickLeft()),
          right: !isArchive && (() => isTop() && onClickRight()),
          up: () => {
            window.scrollY < 10
              ? history.push({
                  pathname: "/info",
                  state: { fromPage: history.location.pathname },
                })
              : window.scrollTo({ top: 0, behavior: "smooth" })
          },
          down: scrollDown,
        }
      : {}
  )

  return (
    <>
      <div className={`project ${slug} ${isLoading ? "loading" : ""}`}>
        <ProjectHeader
          onClickDown={scrollDown}
          showLeftRightNav={!isArchive}
          videoUrl={videoUrls && videoUrls.length > 0 && videoUrls[0]}
          {...{ title, client, image, srcset, onClickLeft, onClickRight }}
        />
        <div ref={mergeRefs([scrollRef, measureRef])}>
          <ProjectSummary summaryText={description} tags={tags} />
          <ProjectBody project={projectBody} isLoading={isLoading} />
        </div>
      </div>
      {isCurrent && y < -window.innerHeight * 1.2 && (
        <ProjectFooter
          onNextClick={onClickFooterNext}
          nextText={footerNextText}
        />
      )}
    </>
  )
}

export default SliderProject
