import React from "react"

import { useProjects } from "./api"

const ApiContext = React.createContext({})

export const ApiProvider = ({ children }) => {
  const { projects, info, isLoading, error } = useProjects()

  return (
    <ApiContext.Provider
      value={{
        info,
        projects,
        isAppReady: !isLoading && !error,
        globalErrorMessage: error ? error.toString() : null,
      }}
    >
      {children}
    </ApiContext.Provider>
  )
}

export default ApiContext
