import React from "react"
import ReactDOM from "react-dom"
import smoothscroll from "smoothscroll-polyfill"

import "./index.scss"
import App from "./components/App"
import * as serviceWorker from "./serviceWorker"
import isTouchDevice from "./library/isTouchDevice"

smoothscroll.polyfill()

document.body.classList.add(isTouchDevice() ? "touch" : "no-touch")

ReactDOM.render(<React.StrictMode>
    <App/>
</React.StrictMode>, document.getElementById("root"))

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister()

const nextCursor = document.getElementById("next_btn")

nextCursor && document.addEventListener("mousemove", function (event) {
    let posX = event.clientX
    let posY = event.clientY
    nextCursor.style.top = posY + "px"
    nextCursor.style.left = posX + "px"
})


// Snap designated elements
// let scrollTimerBot;
// let scrollTimerTop;
// const snapThresholdPi = 0.3;

// function snapTopWithinViewport() {
//     clearTimeout(scrollTimerTop);
//     let sliderWrapper = document.querySelector('.slider-wrapper');
//     let targetWrapper;
//     if (!document.querySelector('.info')) {
//         if (window.getComputedStyle(sliderWrapper).display !== 'none') {
//             targetWrapper = document.querySelector('.slider-wrapper');
//             if (targetWrapper) {
//                 let style = window.getComputedStyle(targetWrapper);
//
//                 if (style.display !== 'none') {
//                     scrollTimerTop = setTimeout(() => {
//                         const snapElements = document.querySelectorAll('.snapTop');
//                         const viewportHeight = window.innerHeight;
//                         const snapThreshold = snapThresholdPi * viewportHeight; // 20% of the viewport height
//
//                         snapElements.forEach(element => {
//                             let parentElement = element.parentElement;
//                             let ignoreElement = false;
//
//                             // Check if any parent element has the class ".slider-item" with overflow set to "hidden"
//                             while (parentElement) {
//                                 if (parentElement.classList.contains('slider-item') && parentElement.style.overflow === 'hidden') {
//                                     ignoreElement = true;
//                                     break;
//                                 }
//                                 parentElement = parentElement.parentElement;
//                             }
//
//                             if (!ignoreElement) {
//                                 const elementTop = element.getBoundingClientRect().top + window.scrollY;
//
//                                 // Check if the element's top edge is within the viewport and not scrolled past
//                                 if (elementTop - window.scrollY <= snapThreshold && elementTop + snapThreshold >= window.scrollY) {
//                                     element.scrollIntoView({behavior: 'smooth', block: 'start'});
//                                 }
//                             }
//                         });
//                     }, 150); // Adjust this value to set the delay after which snapping occurs after scrolling stops
//                 }
//             }
//         } else {
//             scrollTimerTop = setTimeout(() => {
//                 const snapElements = document.querySelectorAll('.snapTop');
//                 const viewportHeight = window.innerHeight;
//                 const snapThreshold = snapThresholdPi * viewportHeight; // 20% of the viewport height
//
//                 snapElements.forEach(element => {
//                     let parentElement = element.parentElement;
//                     let ignoreElement = false;
//
//                     // Check if any parent element has the class ".slider-item" with overflow set to "hidden"
//                     while (parentElement) {
//                         if (parentElement.classList.contains('slider-item') && parentElement.style.overflow === 'hidden') {
//                             ignoreElement = true;
//                             break;
//                         }
//                         parentElement = parentElement.parentElement;
//                     }
//
//                     if (!ignoreElement) {
//                         const elementTop = element.getBoundingClientRect().top + window.scrollY;
//
//                         // Check if the element's top edge is within the viewport and not scrolled past
//                         if (elementTop - window.scrollY <= snapThreshold && elementTop + snapThreshold >= window.scrollY) {
//                             element.scrollIntoView({behavior: 'smooth', block: 'start'});
//                         }
//                     }
//                 });
//             }, 150); // Adjust this value to set the delay after which snapping occurs after scrolling stops
//         }
//     }
// }

// function snapBotWithinViewport() {
//     clearTimeout(scrollTimerBot);
//     let sliderWrapper = document.querySelector('.slider-wrapper');
//     let targetWrapper;
//     if (!document.querySelector('.info')) {
//         if (window.getComputedStyle(sliderWrapper).display !== 'none') {
//             targetWrapper = document.querySelector('.slider-wrapper');
//             if (targetWrapper) {
//                 let style = window.getComputedStyle(targetWrapper);
//
//                 if (style.display !== 'none') {
//                     scrollTimerBot = setTimeout(() => {
//                         const snapElements = document.querySelectorAll('.snapBot');
//                         const viewportHeight = window.innerHeight;
//                         const snapThreshold = snapThresholdPi * viewportHeight; // 20% of the viewport height
//
//                         snapElements.forEach(element => {
//                             let parentElement = element.parentElement;
//                             let ignoreElement = false;
//
//                             // Check if any parent element has the class ".slider-item" with overflow set to "hidden"
//                             while (parentElement) {
//                                 if (parentElement.classList.contains('slider-item') && parentElement.style.overflow === 'hidden') {
//                                     ignoreElement = true;
//                                     break;
//                                 }
//                                 parentElement = parentElement.parentElement;
//                             }
//
//                             if (!ignoreElement) {
//                                 const elementBottom = element.getBoundingClientRect().bottom + window.scrollY;
//
//                                 // Check if the element's bottom edge is within the viewport and not scrolled past
//                                 if (elementBottom - snapThreshold <= window.scrollY && elementBottom + snapThreshold >= window.scrollY) {
//                                     const yOffset = element.offsetHeight + 1;
//                                     const distanceToScroll = element.getBoundingClientRect().top + window.scrollY + yOffset;
//                                     window.scrollTo({top: distanceToScroll, behavior: 'smooth'})
//                                 }
//                             }
//                         });
//                     }, 150); // Adjust this value to set the delay after which snapping occurs after scrolling stops
//                 }
//             }
//         } else {
//             targetWrapper = document.querySelectorAll('#root');
//             if (targetWrapper) {
//                 scrollTimerBot = setTimeout(() => {
//                     const snapElements = document.querySelectorAll('.snapBot');
//                     const viewportHeight = window.innerHeight;
//                     const snapThreshold = snapThresholdPi * viewportHeight; // 20% of the viewport height
//
//                     snapElements.forEach(element => {
//                         let parentElement = element.parentElement;
//                         let ignoreElement = false;
//
//                         // Check if any parent element has the class ".slider-item" with overflow set to "hidden"
//                         while (parentElement) {
//                             if (parentElement.classList.contains('slider-item') && parentElement.style.overflow === 'hidden') {
//                                 ignoreElement = true;
//                                 break;
//                             }
//                             parentElement = parentElement.parentElement;
//                         }
//
//                         if (!ignoreElement) {
//                             const elementBottom = element.getBoundingClientRect().bottom + window.scrollY;
//
//                             // Check if the element's bottom edge is within the viewport and not scrolled past
//                             if (elementBottom - snapThreshold <= window.scrollY && elementBottom + snapThreshold >= window.scrollY) {
//                                 const yOffset = element.offsetHeight + 1;
//                                 const distanceToScroll = element.getBoundingClientRect().top + window.scrollY + yOffset;
//                                 window.scrollTo({top: distanceToScroll, behavior: 'smooth'})
//                             }
//                         }
//                     });
//                 }, 150); // Adjust this value to set the delay after which snapping occurs after scrolling stops
//
//             }
//         }
//     }
//
// }

// function isTextContainerVisible() {
//     const sliderWrapper = document.querySelector('.slider-wrapper');
//     let sliderItems;
//
//     if (window.getComputedStyle(sliderWrapper).display !== 'none') {
//         sliderItems = document.querySelectorAll('.slider-item');
//         for (let item of sliderItems) {
//             if (window.getComputedStyle(item).zIndex === '0') {
//                 const textContainer = item.querySelector('.text_container');
//
//                 if (textContainer) {
//                     const rect = textContainer.getBoundingClientRect();
//                     return (
//                         rect.top <= 100 &&
//                         rect.bottom >= (window.innerHeight || document.documentElement.clientHeight) - window.innerHeight
//                     );
//                 }
//             }
//         }
//     } else {
//         sliderItems = document.querySelectorAll('#root > .project');
//         for (let item of sliderItems) {
//             const textContainer = item.querySelector('.text_container');
//
//             if (textContainer) {
//                 const rect = textContainer.getBoundingClientRect();
//                 return (
//                     rect.top <= 100 &&
//                     rect.bottom >= (window.innerHeight || document.documentElement.clientHeight) - window.innerHeight
//                 );
//             }
//         }
//     }
//
//     return false;
// }

// function checkTextContainer() {
//     const navContainer = document.querySelector('.nav_container');
//
//     if (navContainer) {
//         if (isTextContainerVisible()) {
//             navContainer.classList.add('introVisible');
//         } else {
//             navContainer.classList.remove('introVisible');
//         }
//     }
// }

// window.addEventListener('scroll', () => {
//     checkTextContainer()
//     clearTimeout(scrollTimerBot);
//     clearTimeout(scrollTimerTop);
//     scrollTimerBot = setTimeout(snapBotWithinViewport, 150); // Execute the function after the scroll event has ended
//     scrollTimerTop = setTimeout(snapTopWithinViewport, 150); // Execute the function after the scroll event has ended
// });

// window.addEventListener('resize', () => {
//     clearTimeout(scrollTimerBot);
//     clearTimeout(scrollTimerTop);
//     scrollTimerBot = setTimeout(snapBotWithinViewport, 150); // Execute the function after the scroll event has ended
//     scrollTimerTop = setTimeout(snapTopWithinViewport, 150); // Execute the function after the scroll event has ended
// });

