import React, { useContext } from "react";
import { Link, useHistory } from "react-router-dom";

import LanguageChooser from "./LanguageChooser";
import ScrollContext from "../../library/ScrollContext";

const footerVisible = (scrollPos) =>
    window.innerHeight >= document.documentElement.scrollHeight ||
    scrollPos + window.innerHeight < document.documentElement.scrollHeight - 30;

const introVisible = () => {
    const textContainer = document.querySelector(".text_container");
    if (!textContainer) return false;

    const textRect = textContainer.getBoundingClientRect();
    return textRect.top < window.innerHeight - textRect.height + 60 && textRect.bottom > 0;
};

const TopNavigation = ({ setOverlayActive, goHome = false }) => {
    const history = useHistory();
    const { scrollPos } = useContext(ScrollContext);

    return (
        <div
            className={`nav_container ${
                !footerVisible(scrollPos) ? "footer" : ""
            } ${introVisible() ? "intro" : ""}`}
        >
            <div className="left">
                <div
                    className="desk_nav_home p btn square white"
                    onMouseOver={() => setOverlayActive(true)}
                    onMouseOut={() => setOverlayActive(false)}
                    onClick={() => {
                        window.scrollTo({ top: 0, behavior: "smooth" });
                        if (goHome) history.push("/");
                    }}
                >
                    <span>Studio Flux</span>
                </div>

                <Link
                    to={{
                        pathname: "/info",
                        state: { fromPage: history.location.pathname },
                    }}
                    className="desk_nav_info p btn trans white"
                >
                    <span>I</span>
                    <span>nformation</span>
                </Link>

            </div>
            <div className="right">
                {/*<Link*/}
                {/*    to={{*/}
                {/*        pathname: "/info",*/}
                {/*        state: { fromPage: history.location.pathname },*/}
                {/*    }}*/}
                {/*    className="desk_nav_index"*/}
                {/*>*/}
                {/*    <span>I</span>*/}
                {/*    <span>nfo</span>*/}
                {/*</Link>*/}
                <LanguageChooser />
            </div>
        </div>
    );
};

export default TopNavigation;
