import React, { useState, useContext } from "react"
import { orderBy } from "lodash-es"

import ProjectCase from "./ProjectCase"
import LanguageContext from "../../components/LanguageContext"

const IndexList = ({ projects }) => {
  const { translation } = useContext(LanguageContext)

  // States are: all_closed, any single tag, all_open
  const [listState, setListState] = useState({
    activeTag: undefined,
    allOpen: true,
  })

  return (
    <div id="index" className="flux_archive_container">
      <div id="list_navigation_container">
        <div className="list_navigation_title h2">{translation.index}</div>
        <div
          className={`list_navigation ${
            listState.allOpen ? "image_view" : "list_view"
          }`}
          onClick={() =>
            setListState({
              allOpen: !listState.allOpen,
              activeTag: undefined,
            })
          }
        ></div>
      </div>

      <div className="cases">
        {orderBy(
          projects,
          ["year", "client", "type"],
          ["desc", "asc", "asc"]
        ).map((p) => (
          <ProjectCase
            key={p.id}
            project={p}
            open={listState.allOpen || p.tags.includes(listState.activeTag)}
            setActiveTag={(tag) => {
              setListState({
                allOpen: false,
                activeTag: tag,
              })
            }}
            activeTag={listState.activeTag}
          />
        ))}
      </div>
    </div>
  )
}

export default IndexList
