import React from "react"
import { Link } from "react-router-dom"
import Video from "./Video"

const ProjectList = React.forwardRef(({ title, projectLinks }, ref) => (
    <div className="edition_container snapTop" ref={ref}>
        <div className="title">{title}</div>
        <div className="edition_list">
            {projectLinks.map((m) => (
                <Link key={m.id || m.title} className="item h2 noMargin" to={m.to} onClick={m.onClick}>
                    {m.title}
                    {m.videoUrls && m.videoUrls.length ? (
                        <Video videoUrl={m.videoUrls[0]} imgUrl={m.image} className="bg_img"/>
                    ) : (
                        <img className="bg_img" src={m.image} srcSet={m.srcset} sizes="100vw" alt={m.title}/>
                    )}
                </Link>
            ))}
        </div>
    </div>
))

export default ProjectList
