import { useEffect, useState } from "react"
import axios from "axios"

const client = axios.create({ baseURL: "https://api.are.na/v2" })

const useArenaChannel = (channelSlug, limit = 20) => {
  const [blocks, setBlocks] = useState([])

  useEffect(() => {
    const fetch = async () => {
      const channelLength = await client
        .get(`channels/${channelSlug}/thumb`)
        .then((d) => d.data.length)

      // to get the last "limit" items, we create a page size of
      // (total items - limit), so page 2 contains just "limit" items
      const fakePageLimit = channelLength - limit
      const blocksResponse = await client.get(
        `channels/${channelSlug}/contents`,
        { params: { per: fakePageLimit, page: 2 } }
      )

      setBlocks(blocksResponse.data.contents.reverse())
    }

    if (channelSlug) fetch()
  }, [channelSlug, limit])

  return blocks
}

export default useArenaChannel
