import React from "react"
import { useHistory } from "react-router-dom"
import TagManager from "react-gtm-module"

TagManager.initialize({
  gtmId: "GTM-5X22NJT",
})

const pageview = (path, title) =>
  TagManager.dataLayer({
    dataLayer: {
      event: "pageview",
      page: { path, title },
    },
  })

pageview(window.location.pathname, document.title)

const GAnalytics = () => {
  const history = useHistory()
  history.listen((location) =>
    pageview(location.pathname + location.search, document.title)
  )
  return <></>
}

export default GAnalytics
