import React, { useContext } from "react"
import { sortBy } from "lodash-es"

import LanguageContext from "../../components/LanguageContext"
import ProjectList from "../../components/ProjectList"

const ProjectGroup = ({ groupMembers }) => {
  const { translation } = useContext(LanguageContext)
  return (
    <ProjectList
      title={translation.more}
      projectLinks={sortBy(groupMembers, (m) => m.title).map((m) => ({
        ...m,
        to: {
          pathname: `/projects/${m.slug}`,
          state: { fromGroup: true, noTransition: true },
        },
        onClick: () => window.scrollTo(0, 0),
      }))}
    />
  )
}

export default ProjectGroup
