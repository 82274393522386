import React, { useContext } from "react"

import { StaticClouds } from "../../components/IdleOverlay"
import LanguageContext from "../../components/LanguageContext"

const Header = ({ showSignature, greetingUrl }) => {
  const { translation } = useContext(LanguageContext)
  return (
    <div id="header" className="section snapTop">
      <div className="next p" id="next_btn">
        {translation.sliderRight}
      </div>
      {showSignature && (
        <div className="signature">
          {greetingUrl ? (
            <img src={greetingUrl} alt="Handwritten greeting" />
          ) : (
            <div>Studio Flux</div>
          )}
        </div>
      )}
      <StaticClouds active />
    </div>
  )
}

export default Header
