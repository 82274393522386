import React, { useContext, useEffect, useRef } from "react"
import { useHistory } from "react-router-dom"

import ApiContext from "../../library/ApiContext"
import ArenaChannel from "./ArenaChannel"
import IndexList from "./IndexList"

const InfoPage = () => {
  const history = useHistory()
  const { projects, info } = useContext(ApiContext)

  const scrollRef = useRef(null)
  useEffect(() => {
    history.location.state && history.location.state.toList
      ? scrollRef.current.scrollIntoView()
      : window.scrollTo(0, 0)
  }, [history.location.state])

  const [left, right] = info.addresses.split("<hr />", 2)

  return (
      <>
          <div className="btn closeCurrent" onClick={() =>
              history.push({
                  pathname:
                      (history.location.state && history.location.state.fromPage) ||
                      "/",
                  state: {noTransition: true},
              })
          }>
              <div className="line"></div>
              <div className="line"></div>
          </div>

          <div className="info">
              <div className="flux_info_container">
                  <div className="about h2" dangerouslySetInnerHTML={{__html: info.summary}}/>

                  <div className="contact noMargin" ref={scrollRef}>
                      <div className="left" dangerouslySetInnerHTML={{__html: left}}/>
                      <div className="right" dangerouslySetInnerHTML={{__html: right}}/>
                  </div>
              </div>

              <IndexList projects={projects}/>

              <div className="flux_info_container">
                  <div className="imprint" dangerouslySetInnerHTML={{__html: info.imprint}}/>
              </div>

              <ArenaChannel/>
          </div>
      </>
  )
}

export default InfoPage
