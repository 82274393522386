import React, { useState } from "react"

import ApiContext, { ApiProvider } from "../../library/ApiContext"
import { ScrollProvider } from "../../library/ScrollContext"
import IdleOverlay from "../../components/IdleOverlay"
import Splash from "./Splash"
import WebsitePage from "./WebsitePage"

const Website = () => {
  const [isOverlayActive, setOverlayActive] = useState(false)

  return (
    <ApiProvider>
      <ApiContext.Consumer>
        {({ isAppReady }) => (
          <>
            <IdleOverlay active={!isAppReady || isOverlayActive} />

            {!isAppReady ? (
              <Splash />
            ) : (
              <ScrollProvider>
                <WebsitePage setOverlayActive={setOverlayActive} />
              </ScrollProvider>
            )}
          </>
        )}
      </ApiContext.Consumer>
    </ApiProvider>
  )
}

export default Website
