import React, {useContext} from "react"
import LanguageContext from "../../components/LanguageContext"
import useMeasure from "react-use-measure"

import useArenaChannel from "../../library/useArenaChannel"
import ArenaBlock from "./ArenaBlock"


const ArenaChannel = () => {
    const [ref, {y}] = useMeasure({scroll: true})
    const blocks = useArenaChannel(
        y !== 0 && y < 2 * window.innerHeight && "discovery-channel"
    )
    const {translation} = useContext(LanguageContext)

    return (
        <>
            <div className="arena">
                <h2 className="noMargin" ref={ref}>{translation.feed}</h2>
                {!!blocks?.length && (
                    <div className="arena-blocks">
                        {blocks.map((block) => (
                            <ArenaBlock key={block.id} block={block}/>
                        ))}
                    </div>
                )}
            </div>
        </>
    )
}

export default ArenaChannel
