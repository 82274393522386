import React, {useContext} from "react"

// import LanguageChooser from "./LanguageChooser"
import LanguageContext from "../../components/LanguageContext"
import Video from "../../components/Video"

const ProjectHeader = ({
                           title,
                           client,
                           image,
                           srcset,
                           videoUrl,
                           showLeftRightNav,
                           onClickDown,
                           onClickLeft,
                           onClickRight,
                       }) => {
    const {translation} = useContext(LanguageContext)
    return (
        <div className="header_container" id="header_container">

            <div className="header_navigation">
                {showLeftRightNav && onClickLeft && (
                    <div className="desk_nav_last p" onClick={onClickLeft}>
                        <div className="arrow">←</div>
                        <div className="bullet">•</div>
                        <div className="word">{translation.sliderLeft}</div>
                    </div>
                )}
                <div className="desk_nav_star p" onClick={onClickDown}>
                    <div>{translation.sliderMiddle}</div>
                </div>
                {showLeftRightNav && onClickRight && (
                    <div className="desk_nav_next p" onClick={onClickRight}>
                        <div className="word">{translation.sliderRight}</div>
                        <div className="bullet">•</div>
                        <div className="arrow">→</div>
                    </div>
                )}
            </div>

            <div className="header_title noMargin">
                <h1 className="p">{title}</h1>
                <h2 className="p">{client}</h2>
                <div className="gradient"></div>
            </div>

            <div className="header_image">
                {videoUrl ? (
                    <Video videoUrl={videoUrl} imgUrl={image}/>
                ) : (
                    <img src={image} alt={title} srcSet={srcset} sizes="100vw"/>
                )}
            </div>
        </div>
    )
}

export default ProjectHeader
