import React from 'react'
import { useContext } from 'react'

import { StaticClouds } from '../../components/IdleOverlay'
import LanguageContext from '../../components/LanguageContext'

const Footer = React.forwardRef(({ signOffFooterContent }, ref) => {
  const { translation } = useContext(LanguageContext)

  if (signOffFooterContent.trim().length === 0) {
    let signOffs = [
      'Dawai!',
      'Ciao for now.',
      'That’s it!',
      'Let’s go!',
      'Allons-y!',
      'Fertig.',
      'Voilà.',
      'Hajime!',
      'Cut!',
      'That’s a wrap!',
      'Yalla!',
      'On y va!',
      '¡Ándale!',
      'Shalom.',
      'Shukran!',
      'Kippis!',
      'Mennään!',
      'Mukava!',
      'Ole hyvä!',
    ]
    signOffFooterContent = signOffs[Math.floor(Math.random() * signOffs.length)]
  }

  return (
      <div className="section" id="voila" ref={ref}>
        <div className="sign_off p" dangerouslySetInnerHTML={{__html: signOffFooterContent}}></div>

        <div className="loader"></div>

        <div className="footnote_container noMargin">
          <div className="line_01">
            <div className="tag_link a p" onClick={() => window.scrollTo({
              top: 0,
              behavior: "smooth"
            })}>{translation.backToTop}
            </div>
          </div>
          <div className=" line_02">
            <a className="tag_link left p" href="mailto:hey@studioflux.ch">hey@studioflux.ch</a>
            &emsp;
            <a className="tag_link right p" href="tel:+41 31 511 50 59">+41 (0) 31 511 50 59</a>
          </div>
        </div>

        <StaticClouds/>
      </div>
  )
})

export default Footer
