import React, { useState } from "react"

const FlexImage = ({ url, srcSet, alt }) => {
  const [dimensions, setDimensions] = useState({
    ratio: 1,
    sizes: 1,
    updated: false,
  })

  return (
    <div style={{ flex: dimensions.ratio }}>
      <img
        src={url}
        srcSet={srcSet}
        sizes={`${dimensions.sizes}px`}
        alt={alt}
        onLoad={(event) =>
          !dimensions.updated &&
          setDimensions({
            ratio: event.target.width / event.target.height,
            sizes: event.target.width,
            updated: true,
          })
        }
      />
    </div>
  )
}

export default FlexImage
