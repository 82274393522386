import React, { useContext } from "react"

import LanguageContext from "../../components/LanguageContext"

const ProjectFooter = ({ onNextClick, nextText }) => {
  const { translation } = useContext(LanguageContext)
  return (
    <div className="footer_container">
      <div onClick={onNextClick} className="next_container">
        <span className="link h2">{nextText || translation.nextProject}</span>
      </div>
      <div className="footnote_container noMargin">
        <div className="line_01">
          <div
            className="tag_link a p"
            onClick={() => window.scrollTo({ top: 0, behavior: "smooth" })}
          >{translation.backToTop}
          </div>
        </div>
        <div className=" line_02">
          <a className="tag_link left p" href="mailto:hey@studioflux.ch">hey@studioflux.ch</a>
          &emsp;
          <a className="tag_link right p" href="tel:+41 31 511 50 59">+41 (0) 31 511 50 59</a>
        </div>
      </div>
    </div>
  )
}

export default ProjectFooter
