import { useEffect } from "react"

const adjustVh = () => {
  const vh = window.innerHeight / 100
  document.documentElement.style.setProperty("--vh", `${vh}px`)
}

const useAdjustVh = () => {
  useEffect(() => {
    adjustVh()
    window.addEventListener("resize", adjustVh, { passive: true })
    return () =>
      window.removeEventListener("resize", adjustVh, { passive: true })
  })
}

export default useAdjustVh
