import React, { useContext } from "react"

import ProjectList from "../../components/ProjectList"
import LanguageContext from "../../components/LanguageContext"
import PortfolioProject from "./PortfolioProject"

const ProjectsStack = React.forwardRef(({ projects }, ref) => {
  const { translation } = useContext(LanguageContext)
  return (
    <>
      <ProjectList
        title={translation.content}
        ref={ref}
        projectLinks={projects.map((p) => ({
          ...p,
          title: p.client,
          to: {},
          onClick: (e) => {
            e.nativeEvent.stopImmediatePropagation()
            p.ref.current.scrollIntoView({ behavior: "smooth" })
          },
        }))}
      />

      {projects.map((p) => (
        <PortfolioProject key={p.id} slug={p.slug} scrollRef={p.ref} />
      ))}
    </>
  )
})

export default ProjectsStack
