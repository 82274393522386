import React, { useEffect, useState } from "react"
import { useLocation } from "react-router-dom"
import { useSprings, animated, to } from "@react-spring/web"
import { useDrag } from "react-use-gesture"

import Project from "./SliderProject"
import isTop from "../../library/isTop"

// mathematically 'true' modulo
const mod = (i, m) => ((i % m) + m) % m

// nearest modulo
const nearestMod = (i, m) => mod(i + m / 2, m) - m / 2

// Specifies the desired end state of the animation. Spring will take  care of
// transitioning there.
const animationTarget = (
  index,
  projectFixed,
  active = false,
  xMove = 0,
  immediate = false
) => (i) => {
  // The nearest offest index, 0 for current slide, -1 for previous, +1 for next
  // and this works circularly accross the end of the list.
  const nearestIndex = nearestMod(i - index, projectFixed.length)
  const deltaX = active ? xMove : 0

  return {
    display: Math.abs(nearestIndex) <= 1 ? "flex" : "none",
    xMove,
    width: `calc(${nearestIndex > 0 ? 0 : 100}vw - ${
      nearestIndex === 1 || nearestIndex === 0 ? deltaX : 0
    }px)`,
    maxHeight: nearestIndex === 0 ? "999999999vh" : "100vh",
    zIndex: nearestIndex,
    immediate: immediate || active,
    position: projectFixed[i] ? "fixed" : "absolute",
  }
}

const Slider = ({ visible, projects, index, setIndex }) => {
  const nextIndex = (offset) => mod(index + offset, projects.length)

  const [isProjectFixed, setFixedArray] = useState(
    Array(projects.length).fill(false)
  )
  const unsetFixed = () => setFixedArray(Array(projects.length).fill(false))
  const setFixedWithOffset = (offset) => {
    const i = nextIndex(offset)
    setFixedArray(isProjectFixed.fill(true, i, i + 1))
  }

  // Init the spring with the initial animation target of index=0.
  const [props, api] = useSprings(
    projects.length,
    animationTarget(index, isProjectFixed)
  )

  const location = useLocation()

  // Also trigger animation on external index change
  useEffect(() => {
    if (index !== undefined)
      api.start(
        animationTarget(
          index,
          isProjectFixed,
          undefined,
          undefined,
          location.state && location.state.noTransition
        )
      )
  }, [index, isProjectFixed, projects, api, location.state])

  // calculate the next index with given offset
  const setIndexWithOffset = (offset) => {
    const newIndex = nextIndex(offset)
    setIndex(newIndex)
    return newIndex
  }

  // On clicks, we'll also want to change the animation target
  const clickNextIndex = () => {
    window.scrollTo({ top: 0, behavior: "smooth" })
    setIndexWithOffset(1)
  }
  const clickPrevIndex = () => {
    window.scrollTo({ top: 0, behavior: "smooth" })
    setIndexWithOffset(-1)
  }

  // On drags, we set the animation target to the current dragging state
  const bind = useDrag(
    ({ active, movement: [xMove], direction: [xDir], cancel, canceled }) => {
      isTop() &&
        // if the mouse/touch is down (i.e. still holding)
        // AND the movement has passed the middle of the window
        (!active && Math.abs(xMove) > window.innerWidth / 2 && !canceled
          ? // cancel and go to the next index
            cancel(setIndexWithOffset(xDir > 0 ? -1 : 1))
          : // otherwise calculate the target of the slide being held
            api.start(animationTarget(index, isProjectFixed, active, xMove)))
    },
    { axis: "x", filterTaps: true }
  )

  return (
    <div
      className="slider-wrapper"
      style={{ display: visible ? "block" : "none" }}
    >
      {props.map((style, i) => (
        <animated.div
          {...bind()}
          key={i}
          className="slider-item"
          style={{
            // Overflow 'initial' on the active project is needed for
            // scroll-snap and for the sticky titles.
            overflow: to([style.zIndex, style.xMove], (z, x) =>
              z === 0 && x === 0 ? "initial" : "hidden"
            ),
            ...style,
          }}
        >
          <Project
            isCurrent={i === index}
            project={projects[i]}
            onClickRight={clickNextIndex}
            onClickLeft={clickPrevIndex}
            onClickFooterNext={() => {
              setFixedWithOffset(1)
              setIndexWithOffset(1)
              // HACK: this would better be with a callback from the animation
              // instead of a fixed timeout.
              setTimeout(() => {
                window.scrollTo(0, 0)
                unsetFixed()
              }, 500)
            }}
          />
        </animated.div>
      ))}
    </div>
  )
}
export default Slider
