import React from "react"
import { Link } from "react-router-dom"

import ImageRow from "./ImageRow"

const ProjectCase = ({ project, open, setActiveTag, activeTag }) => {
  const href = `/projects/${project.slug}`
  return (
    <div className="case p noMargin">
      <div className="title">
        <Link
          to={{ pathname: href, state: { noTransition: true } }}
          onClick={() => window.scrollTo(0, 0)}
        >
          {[project.client, project.type].filter((s) => s).join(", ")}
        </Link>
      </div>

      <div className="tags">
        {project.tags &&
          project.tags.map((t, i) => (
            <div
              key={t}
              onClick={() => setActiveTag(activeTag === t ? undefined : t)}
              className={activeTag === t ? "tag active a" : "tag a"}
              style={{ marginRight: 5 }}
            >
              {i + 1 === project.tags.length ? t : t + ","}
            </div>
          ))}
      </div>

      <div className="year">
        <div>{project.year}</div>
      </div>

      <Link
        to={{ pathname: href, state: { noTransition: true } }}
        onClick={() => window.scrollTo(0, 0)}
      >
        {project.images && (
          <ImageRow
            images={[
              { image: project.image, srcset: project.srcset },
              ...project.images,
            ]}
            open={open}
          />
        )}
      </Link>
    </div>
  )
}

export default ProjectCase
