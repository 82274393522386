import React from "react"

const ProjectSummary = ({ summaryText, tags, client, colourMode }) =>

  summaryText && (

<div className={`text_container snapTop snapBot ${colourMode}`}>
      {client && <div className="project_title h2">{client}</div>}
      <div
        className="project_text h2"
        dangerouslySetInnerHTML={{ __html: summaryText }}
      />
      <div key="tags" className="project_tags">
        {tags
          .map((t, i) => (
            <div key={i} className="tag_link p noMargin">
              <div className="tag_text">{t}</div>
            </div>
          ))
          .reduce(
            (prev, curr, j) => [
              prev,
              <div key={`t${j}`} className="tag_separator">
                  &ensp;
              </div>,
              curr,
            ],
            null
          )}
      </div>
    </div>
  )

export default ProjectSummary
