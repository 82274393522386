import React from "react"
import useMeasure from "react-use-measure"
import { useSpring, animated } from "@react-spring/web"

import FlexImage from "./FlexImage"

const ImageRow = ({ images, open }) => {
  const [rowRef, { height }] = useMeasure()
  const props = useSpring({
    height: open ? height : 0,
    opacity: open ? 1 : 0,
  })

  return (
    <animated.div className="images" style={props}>
      <div className="image_row" ref={rowRef}>
        {images.map(({ image, srcset }, i) => (
          <FlexImage key={i} url={image} srcSet={srcset} height={height} />
        ))}
      </div>
    </animated.div>
  )
}

export default ImageRow
