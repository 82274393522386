import React, { useContext } from "react"
import { useRouteMatch, useHistory } from "react-router-dom"
import { partition } from "lodash-es"

import ApiContext from "../../library/ApiContext"
import Project from "./SliderProject"
import Slider from "./Slider"
import TopNavigation from "./TopNavigation"
import LanguageContext from "../../components/LanguageContext"

const ProjectsRoute = ({ setOverlayActive }) => {
  const { translation } = useContext(LanguageContext)

  const { projects } = useContext(ApiContext)
  const [sliderProjects, archiveProjects] = partition(projects, (p) => p.slider)

  const history = useHistory()
  const match = useRouteMatch("/projects/:slug")
  const setSlug = (i) => {
    history.push(`/projects/${sliderProjects[i].slug}`)
  }

  // Check whether there is a slug in the URL and if the slug matches a slider
  // project. If yes, get that project's index.
  const sliderIndex =
    match &&
    match.params &&
    match.params.slug &&
    sliderProjects.findIndex((p) => p.slug === match.params.slug)

  const showSlider = !!match && sliderIndex >= 0

  // Same for archive projects, if there isn't already a slider project matched.
  const archiveProject =
    match &&
    match.params &&
    match.params.slug &&
    sliderIndex === -1 &&
    archiveProjects.find((p) => p.slug === match.params.slug)

  return (
    <>
      {(showSlider || archiveProject) && (
        <TopNavigation
          goHome={archiveProject}
          setOverlayActive={setOverlayActive}
        />
      )}

      <Slider
        // Show slider if a slider project matches
        visible={showSlider}
        projects={sliderProjects}
        index={sliderIndex}
        setIndex={setSlug}
      />

      {archiveProject && (
        <Project
          project={archiveProject}
          isCurrent
          footerNextText={translation.index}
          onClickFooterNext={() =>
            history.push({
              pathname: "/info",
              state: { fromPage: history.location.pathname, toList: true },
            })
          }
        />
      )}
    </>
  )
}

export default ProjectsRoute
