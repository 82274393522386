import React from "react"

const Video = React.forwardRef(
  ({ videoUrl, imgUrl, className, onLoad }, ref) => (
    <video
      disablePictureInPicture
      loop
      muted
      playsInline
      onLoadedData={onLoad}
      poster={imgUrl}
      autoPlay
      className={className}
      ref={ref}
    >
      <source src={videoUrl} type="video/mp4" />
      <source src={imgUrl} type="image/jpg" />
      Your browser does not support the video tag.
    </video>
  )
)

export default Video
