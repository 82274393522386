import { useEffect, useState, useContext } from "react"
import axios from "axios"

import LanguageContext, { defaultLanguage } from "../components/LanguageContext"

const languagePathPrefix = (lang) =>
  lang === defaultLanguage ? "" : `/${lang}`

const client = axios.create({
  baseURL: process.env.REACT_APP_BASE_URL,
})

export const useProjects = (lang) => {
  const [data, setData] = useState(undefined)
  const [isLoading, setIsLoading] = useState(true)
  const [error, setError] = useState(undefined)
  const { currentLanguage } = useContext(LanguageContext)

  useEffect(() => {
    const fetchData = async () => {
      setIsLoading(true)

      client(`${languagePathPrefix(lang || currentLanguage)}/projects/`)
        .then((result) => setData(result.data))
        .catch((e) => setError(e))
        .finally(() => setIsLoading(false))
    }

    fetchData()
  }, [lang, currentLanguage])

  return { ...data, isLoading, error }
}

export const useProject = (slug, compact = false, lang) => {
  const [project, setProject] = useState(undefined)
  const [isLoading, setIsLoading] = useState(true)
  const [error, setError] = useState(undefined)
  const { currentLanguage } = useContext(LanguageContext)

  useEffect(() => {
    const fetchData = async () => {
      if (slug) {
        setIsLoading(true)

        client(
          `${languagePathPrefix(
            lang || currentLanguage
          )}/projects/${slug}/?compact=${compact}`
        )
          .then((result) => setProject(result.data))
          .catch((e) => setError(e))
          .finally(() => setIsLoading(false))
      }
    }
    fetchData()
  }, [slug, compact, lang, currentLanguage])

  return { project, isLoading, error }
}

export const usePortfolio = (slug, onError = () => {}) => {
  const [portfolio, setPortfolio] = useState(undefined)
  const [isLoading, setIsLoading] = useState(true)

  useEffect(() => {
    const fetchData = async () => {
      if (slug) {
        setIsLoading(true)
        client(`/presentations/${slug}/`)
          .then((result) => setPortfolio(result.data))
          .catch(onError)
          .finally(() => setIsLoading(false))
      }
    }
    fetchData()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [slug])

  return { portfolio, isLoading }
}
