import { useEffect } from "react"

const useArrowKeys = (actions) => {
  useEffect(() => {
    const handler = (e) => {
      if (e.key === "ArrowUp" && actions.up) actions.up(e)
      else if (e.key === "ArrowDown" && actions.down) actions.down(e)
      else if (e.key === "ArrowLeft" && actions.left) actions.left(e)
      else if (e.key === "ArrowRight" && actions.right) actions.right(e)
    }

    document.addEventListener("keydown", handler)
    return () => document.removeEventListener("keydown", handler)
  }, [actions])
}

export default useArrowKeys
