import React from "react"

import { useProject } from "../../library/api"
import ProjectSummary from "../../components/ProjectSummary"
import ProjectGallery from "../../components/ProjectGallery"

const PortfolioProject = ({ slug, scrollRef }) => {
  const { project, isLoading } = useProject(slug, true)
  return (
    !isLoading && (
      <div ref={scrollRef}>
        <ProjectSummary
          client={project.client}
          summaryText={project.description}
          tags={project.tags}
          colourMode={project.mode}
        />
        <ProjectGallery fields={project.fields} />
      </div>
    )
  )
}

export default PortfolioProject
