import React from "react"
import { BrowserRouter as Router, Switch, Route } from "react-router-dom"

import GAnalytics from "./GAnalytics"
import useAdjustVh from "../library/useAdjustVh"
import Website from "../pages/website"
import Portfolio from "../pages/portfolio"
import { LanguageProvider } from "./LanguageContext"

const App = () => {
  useAdjustVh()

  return (
    <LanguageProvider>
      <Router>
        <Switch>
          <Route
            exact
            path={["/", "/project", "/projects", "/projects/:slug", "/info"]}
          >
            <Website />
          </Route>

          <Route>
            <Portfolio />
          </Route>
        </Switch>

        <GAnalytics />
      </Router>
    </LanguageProvider>
  )
}

export default App
