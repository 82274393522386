import React, { useState } from "react"
import Cookies from "js-cookie"

import translations from "../assets/translations.json"

const cookieName = "language"
const languages = ["en", "de"]
export const defaultLanguage = languages[0]

const LanguageContext = React.createContext()
export default LanguageContext

export const LanguageProvider = ({ children }) => {
  const [currentLanguage, setCurrentLanguage] = useState(
    Cookies.get(cookieName) || defaultLanguage
  )

  const translation =
    translations[currentLanguage] || translations[defaultLanguage]

  const unusedLanguages = languages.filter((l) => l !== currentLanguage)

  const setLanguage = (l) => {
    if (languages.includes(l)) {
      Cookies.set(cookieName, l)
      setCurrentLanguage(l)
    }
  }

  return (
    <LanguageContext.Provider
      value={{ currentLanguage, translation, unusedLanguages, setLanguage }}
    >
      {children}
    </LanguageContext.Provider>
  )
}
