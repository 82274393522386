import { useState, useEffect, useRef } from "react"

const activityEvents = [
  "mousemove",
  "mousedown",
  "scroll",
  "mouseup",
  "keydown",
  "keyup",
  "touchstart",
  "touchend",
  "touchmove",
]

const useIsUserIdle = (idleTime) => {
  const [isUserIdle, setIsUserIdle] = useState(false)
  const timer = useRef(null)

  const restartTimer = () => {
    clearTimeout(timer.current)
    timer.current = setTimeout(() => setIsUserIdle(true), idleTime)
  }

  const handleUserActive = () => {
    setIsUserIdle(false)
    restartTimer()
  }

  const addUserActiveHandlers = () =>
    activityEvents.forEach((event) =>
      document.addEventListener(event, handleUserActive)
    )

  const removeUserActiveHandlers = () =>
    activityEvents.forEach((event) =>
      document.removeEventListener(event, handleUserActive)
    )

  useEffect(() => {
    addUserActiveHandlers()
    return removeUserActiveHandlers
  })

  useEffect(() => {
    if (!isUserIdle) {
      restartTimer()
      return () => clearTimeout(timer.current)
    }
  })

  return isUserIdle
}

export default useIsUserIdle
