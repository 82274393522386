import React, { useContext } from "react"

import LanguageContext from "../../components/LanguageContext"

const Intro = ({
  introContent,
  signOffGreeting,
  people,
  partnerContent,
  introRef,
  teamRef,
  portraitsRef,
  partnerRef,
}) => {
  const { translation } = useContext(LanguageContext)
  return (
    <>
      <div
        className="section snapTop h2"
        id="intro"
        ref={introRef}
        dangerouslySetInnerHTML={{ __html: introContent }}
      />
      <div className="section snapTop h2" id="team" ref={teamRef}>
        <p
          className="link"
          dangerouslySetInnerHTML={{ __html: signOffGreeting }}
        />
      </div>

      <div className="section snapTop" id="portraits" ref={portraitsRef}>
        {people.map((p) => (
          <div key={p.name} className="person">
            <div className="head p">
              {p.name}
              <br />
              {p.job}
            </div>
            <img src={p.image} srcSet={p.srcset} sizes="50vw" alt={p.name} />
          </div>
        ))}
      </div>

      <div className="section snapTop" id="partner" ref={partnerRef}>
        <div className="h2" dangerouslySetInnerHTML={{ __html: partnerContent }}></div>
        <div>
          <p className="h2">{translation.currentProjects}</p>
        </div>
      </div>
    </>
  )
}

export default Intro
