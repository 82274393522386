import React, { useContext } from "react"
import { Switch, Route, Redirect } from "react-router-dom"

import ApiContext from "../../library/ApiContext"
import InfoPage from "./InfoPage"
import ProjectsRoute from "./ProjectsRoute"

const WebsitePage = ({ setOverlayActive }) => {
  const { projects } = useContext(ApiContext)

  return (
    <>
      <Switch>
        <Route path="/info">
          <InfoPage />
        </Route>

        {/* dummy placeholder to catch the match */}
        <Route path="/projects/:slug" />

        <Redirect from="*" to={`/projects/${projects[0].slug}`} />
      </Switch>

      {
        // To persist state when info page opens/closes, the slider is
        // permanently rendered (but hidden internally).
      }
      <ProjectsRoute setOverlayActive={setOverlayActive} />
    </>
  )
}

export default WebsitePage
