import React, { createRef } from "react"
import { useContext } from "react"
import { useEffect } from "react"
import { useHistory, useRouteMatch } from "react-router-dom"
import LanguageContext from "../../components/LanguageContext"

import { usePortfolio } from "../../library/api"
import useArrowKeys from "../../library/useArrowKeys"
import Footer from "./Footer"
import Header from "./Header"
import Intro from "./Intro"
import ProjectsStack from "./ProjectsStack"

const scrollThreshold = 10

const scrollDown = (refs) => () =>
  refs
    .find((r) => r.current?.getBoundingClientRect()?.top > scrollThreshold)
    ?.current?.scrollIntoView({ behavior: "smooth" })

const Portfolio = () => {
  const history = useHistory()
  const { params } = useRouteMatch("/:portfolioSlug")
  const { portfolio, isLoading } = usePortfolio(params.portfolioSlug, () =>
    history.push("/")
  )

  // Set language in cookie to language sent as "override" from backend
  const { setLanguage } = useContext(LanguageContext)
  useEffect(() => {
    if (portfolio) setLanguage(portfolio.languageOverride)
  }, [portfolio, setLanguage])

  const [
    headerRef,
    introRef,
    teamRef,
    portraitsRef,
    partnerRef,
    projectListRef,
    footerRef,
  ] = Array.from(Array(7)).map(createRef)

  const projectScrollRefs = Array.from(
    Array(portfolio?.projects?.length || 0)
  ).map(createRef)

  const keyScrollRefs = [
    headerRef,
    introRef,
    teamRef,
    portraitsRef,
    partnerRef,
    projectListRef,
    ...projectScrollRefs,
    footerRef,
  ]

  const scrollDownKeyFn = scrollDown(keyScrollRefs)
  useArrowKeys({
    down: (e) => {
      e.preventDefault()
      scrollDownKeyFn()
    },
    up: (e) => {
      e.preventDefault()
      keyScrollRefs
        .findLast(
          (r) => r.current?.getBoundingClientRect()?.top < -scrollThreshold
        )
        ?.current?.scrollIntoView({ behavior: "smooth" })
    },
  })

  useEffect(() => {
    const handler = scrollDown([
      headerRef,
      introRef,
      teamRef,
      portraitsRef,
      partnerRef,
      projectListRef,
    ])
    document.addEventListener("click", handler)
    return () => document.removeEventListener("click", handler)
  }, [headerRef, introRef, teamRef, portraitsRef, partnerRef, projectListRef])

  return (
    <div className="portfolio" ref={headerRef}>
      <div className="slider-wrapper"></div>
      <button
        id="index_navi"
        onClick={(e) => {
          e.nativeEvent.stopImmediatePropagation()
          projectListRef.current.scrollIntoView()
        }}
      />
      <Header
        showSignature={!isLoading}
        greetingUrl={portfolio && portfolio.greetingSvg}
      />

      {!isLoading && portfolio && (
        <>
          {!portfolio.presentationMode && (
            <Intro
              introContent={portfolio.introduction}
              signOffGreeting={portfolio.signOffGreeting}
              people={portfolio.team}
              partnerContent={portfolio.teamExtended}
              introRef={introRef}
              teamRef={teamRef}
              portraitsRef={portraitsRef}
              partnerRef={partnerRef}
            />
          )}
          <ProjectsStack
            projects={portfolio.projects.map((p, i) => ({
              ...p,
              ref: projectScrollRefs[i],
            }))}
            ref={projectListRef}
          />
          <Footer
            signOffFooterContent={portfolio.signOffFooter}
            ref={footerRef}
          />
        </>
      )}
    </div>
  )
}

export default Portfolio
