import React, { useEffect, useState } from "react"
import { throttle } from "lodash"

const ScrollContext = React.createContext({})

export const ScrollProvider = ({ children }) => {
  useEffect(() => {
    const handleScroll = throttle(() => setScrollPos(window.scrollY), 100)

    window.addEventListener("scroll", handleScroll, { passive: true })
    return () =>
      window.removeEventListener("scroll", handleScroll, { passive: true })
  }, [])

  const [scrollPos, setScrollPos] = useState(0)

  return (
    <ScrollContext.Provider value={{ scrollPos }}>
      {children}
    </ScrollContext.Provider>
  )
}

export default ScrollContext
