import React from "react"

import ProjectGallery from "../../components/ProjectGallery"
import ProjectGroup from "./ProjectGroup"

const ProjectBody = ({ project, isLoading }) => (
  <>
    <div className={`loader_container ${isLoading ? "" : "closed"}`}>
      <img src="/assets/loader.svg" alt="loader" />
    </div>
    {!isLoading && (
      <>
        <ProjectGallery fields={project.fields} />

        {project.group &&
          project.group_members &&
          project.group_members.length > 0 && (
            <ProjectGroup groupMembers={project.group_members} />
          )}
      </>
    )}
  </>
)

export default ProjectBody
