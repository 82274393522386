import React from "react"

import useIsUserIdle from "../library/useIsUserIdle"
import Video from "./Video"

const userIdleTime = 120 * 1000 // seconds * 1000

const uniformInt = (min, max) => Math.floor(Math.random() * (max - min) + min)

const IdleOverlay = ({ active: forceActive }) => {
  const isUserIdle = useIsUserIdle(userIdleTime)
  const randomCloudId = uniformInt(1, 6).toString().padStart(2, "0")

  return (
    <div
      className={`idle_overlay ${isUserIdle || forceActive ? "active" : ""}`}
    >
      <div className="cloud">
        <Video
          key={randomCloudId}
          videoUrl={`/assets/clouds/cloud-${randomCloudId}.mp4`}
          imgUrl={`/assets/clouds/cloud-${randomCloudId}.jpg`}
        />
      </div>

      <div className="red" />
    </div>
  )
}

export const StaticClouds = React.memo(IdleOverlay)

export default IdleOverlay
