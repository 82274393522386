import React from "react"

import Video from "./Video"

const ProjectGallery = ({ fields }) => (
  <div className="gallery_container snapBot">
    {fields.map((field, i) => {
      switch (field.type) {
        case "text":
          return (
            <div
              key={i}
              className="project_text"
              dangerouslySetInnerHTML={{ __html: field.text }}
            />
          )

        case "video":
          return field.urls.map((url) => (
            <div key={`${i}${url}`} className={field.position}>
              <Video videoUrl={url} />
            </div>
          ))

        default:
          return field.urls.map(({ url, srcset, description }) => (
            <React.Fragment key={`${i}${url}`}>
              <div className={field.position}>
                <img
                  src={url}
                  srcSet={srcset}
                  sizes={field.position === "fullwidth" ? "100vw" : "50vw"}
                  alt={description || url}
                />
                {description && (
                  <div className={`gallery_text`}>
                    <p>{description}</p>
                  </div>
                )}
              </div>
            </React.Fragment>
          ))
      }
    })}
  </div>
)

export default ProjectGallery
