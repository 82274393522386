import React, { useState } from "react"

const ArenaBlock = ({ block }) => {
  switch (block.class) {
    case "Image":
      return (
        <ArenaBlockImage
          title={block.generated_title}
          imageUrl={block.image.display.url}
        />
      )
    case "Media":
      return (
        <ArenaBlockMedia
          title={block.generated_title}
          embedHtml={block.embed.html}
        />
      )
    case "Channel":
      return (
        <ArenaBlockText
          title={block.title}
          linkUrl={`https://are.na/channel/${block.id}`}
        />
      )
    default:
      return (
        <ArenaBlockText
          title={block.generated_title}
          linkUrl={block.source.url}
        />
      )
  }
}

const ArenaBlockImage = ({ title, imageUrl }) => {
  const [aspectRatio, setAspectRatio] = useState()

  const handleLoad = (event) => {
    setAspectRatio(event.target.naturalWidth / event.target.naturalHeight)
  }

  return (
    <ArenaBlockWrapper wide={aspectRatio > 1}>
      <img
        alt={title}
        src={imageUrl}
        onLoad={handleLoad}
        style={aspectRatio ? null : { display: "none" }}
      />
    </ArenaBlockWrapper>
  )
}

const ArenaBlockMedia = ({ title, embedHtml }) => {
  const srcUrl = embedHtml.match(/src=['"](.*?)['"]/)[1]

  return (
    <ArenaBlockWrapper wide fixedAspect>
      <iframe title={title} src={srcUrl} allowFullScreen />
    </ArenaBlockWrapper>
  )
}

const ArenaBlockText = ({ title, linkUrl }) => (
  <ArenaBlockWrapper>
    <h4>{title}</h4>
    <a href={linkUrl} target="_blank" rel="noopener noreferrer">
      Read more
    </a>
  </ArenaBlockWrapper>
)

const ArenaBlockWrapper = ({ wide, fixedAspect, children }) => {
  const classes = [
    "arena-block",
    wide ? "wide" : "narrow",
    fixedAspect ? "aspect" : "",
  ].join(" ")

  return <div className={classes}>{children}</div>
}

export default ArenaBlock
