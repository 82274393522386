import React, { useContext } from "react"
import { capitalize } from "lodash-es"

import LanguageContext from "../../components/LanguageContext"

const LanguageChooser = () => {
    const { unusedLanguages, setLanguage } = useContext(LanguageContext)

    const getLanguageName = (code) => {
        return code === "de" ? "Deutsch" : code === "en" ? "English" : capitalize(code);
    };

    return unusedLanguages.map((l) => {
        const languageName = getLanguageName(l);
        return (
            <div key={l} className="desk_nav_lang p btn trans white" onClick={() => setLanguage(l)}>
                <span className="adjust">{languageName.charAt(0)}</span>
                <span>{languageName.slice(1)}</span>
            </div>
        );
    });
}

export default LanguageChooser
